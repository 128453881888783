.App{
  background-color: #f8f8f8;
  min-height: 100vh;
}
/*
文字溢出处理
 */
.line-1 {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden
}

.line-2 {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden
}

.line-3 {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}

/*
  不换行
*/
.no-wrap {
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}